import axios from 'axios';
import Cookie from 'js-cookie';

import {
  ADD_ATTENDANCE_REQUEST,
  ADD_ATTENDANCE_SUCCESS,
  ADD_ATTENDANCE_FAIL,
  ADD_MANUAL_REQUEST,
  ADD_MANUAL_SUCCESS,
  ADD_MANUAL_FAIL,
  CONFIRM_MANUAL_REQUEST,
  CONFIRM_MANUAL_SUCCESS,
  CONFIRM_MANUAL_FAIL,
  ADD_PARTICIPANT_REQUEST,
  ADD_PARTICIPANT_FAIL,
  ADD_PARTICIPANT_SUCCESS,
  CLEAR_ORDERS,
} from '../types/scannerTypes';

export const addToAttendance =
  (qrData, table = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_ATTENDANCE_REQUEST });

      const qr = JSON.parse(qrData);

      const { data } = await axios.post(
        'https://event.cmac.world/app_handler.php',
        {
          secret_code: qr?.secret_code,
          customer_id: qr?.customer_id,
          product_id: qr?.product_id,
          order_id: qr?.order_id,
          table,
        },
        {
          headers: {
            'WG-Method': 'VALIDATE_QR',
            'WG-Key': Cookie.get('accessToken'),
          },
        }
      );

      dispatch({ type: ADD_ATTENDANCE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ADD_ATTENDANCE_FAIL,
        payload: error?.response?.data?.error,
      });
    }
  };

export const getOrderParticipants = (customer) => async (dispatch) => {
  try {
    dispatch({ type: ADD_MANUAL_REQUEST });

    const { data } = await axios.post(
      'https://event.cmac.world/app_handler.php',
      { customer },
      {
        headers: {
          'WG-Method': 'GET_ORDER_PARTICIPANTS',
          'WG-Key': Cookie.get('accessToken'),
        },
      }
    );

    dispatch({ type: ADD_MANUAL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_MANUAL_FAIL, payload: error.response?.data?.error });
  }
};

export const addManualScanConfirm =
  (customerId, table = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: CONFIRM_MANUAL_REQUEST });

      const { data } = await axios.post(
        'https://event.cmac.world/app_handler.php',
        { customer_id: customerId, table },
        {
          headers: {
            'WG-Method': 'VALIDATE_MANUAL_CONFIRM',
            'WG-Key': Cookie.get('accessToken'),
          },
        }
      );

      dispatch({ type: CONFIRM_MANUAL_SUCCESS, payload: data.message });
    } catch (error) {
      dispatch({
        type: CONFIRM_MANUAL_FAIL,
        payload: error?.response?.data?.error,
      });
    }
  };

export const addParticipant = (customerId, orderId) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PARTICIPANT_REQUEST });

    const { data } = await axios.post(
      'https://event.cmac.world/app_handler.php',
      { participant_id: customerId, order_id: orderId },
      {
        headers: {
          'WG-Method': 'ADD_PARTICIPANT',
          'WG-Key': Cookie.get('accessToken'),
        },
      }
    );

    dispatch({ type: ADD_PARTICIPANT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_PARTICIPANT_FAIL });
  }
};

export const clearOrders = () => (dispatch) => {
  dispatch({ type: CLEAR_ORDERS });
};
