import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Content from '../../components/layout/Content';
import { login } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';

import './Login.css';

const Login = (props) => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const { user, loading, errors } = authSelector;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(login(email, password));
  };

  useEffect(() => {
    dispatch(closeMenu());
  }, [dispatch]);

  return (
    <Fragment>
      <Header isLogin />
      {!user ? (
        <Content>
          <h1>CMAC Event Scanner</h1>
          <span className='login__welcome'>
            Please log in with your venue email and password
          </span>
          <form className='login__form' onSubmit={onSubmit}>
            <div className='form-group'>
              <label className='form__label' htmlFor='email'>
                Email
              </label>
              <input
                className='form__input'
                type='text'
                id='email'
                name='email'
                value={email}
                autoComplete={false}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className='error'>{errors?.email?.message}</div>
            </div>
            <div className='form-group'>
              <label className='form__label' htmlFor='password'>
                Password
              </label>
              <input
                className='form__input'
                type='password'
                id='password'
                name='password'
                value={password}
                autoComplete={false}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='error'>{errors?.password?.message}</div>
            </div>
            <div className='form__buttons'>
              <button
                aria-label='Sign in'
                className='form__button login__button'
                style={{ width: '100%' }}
                type='submit'
              >
                Sign In{' '}
                <PulseLoader
                  loading={loading}
                  color={'#ffffff'}
                  css={'margin-left: 8px'}
                  size={5}
                />
              </button>
            </div>
          </form>
        </Content>
      ) : (
        <Redirect push to='/' />
      )}
    </Fragment>
  );
};

export default Login;
