import React from 'react';

const Participant = ({
  participant: { participant_fullname, option_name },
}) => {
  return (
    <div className='participantItem'>
      <h4>{participant_fullname}</h4>
      <p>{option_name}</p>
    </div>
  );
};

export default Participant;
