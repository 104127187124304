import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from './layout/Alert';
import ParticipantList from './ParticipantList';
import { ReactComponent as Cross } from '../assets/cross.svg';
import { ReactComponent as Check } from '../assets/check.svg';

import { addParticipant } from '../actions/scannerActions';
import { useEffect } from 'react';
import { CLEAR_CURRENT } from '../types/scannerTypes';

const OrdersList = ({ orders }) => {
  const dispatch = useDispatch();
  const scannerSelector = useSelector((state) => state.scanner);
  const { current, error } = scannerSelector;

  const [alert, setAlert] = useState(null);

  const handleDismiss = () => {
    setAlert(null);
    dispatch({ type: CLEAR_CURRENT });
  };

  useEffect(() => {
    if (error) {
      setAlert({
        icon: <Cross style={{ color: '#e53e3e' }} />,
        message: error,
      });
    } else if (current) {
      setAlert({
        icon: <Check style={{ color: '#48BB78' }} />,
        message: current,
      });
    }
  }, [current, error]);

  return (
    <>
      {orders.map((order) => (
        <div key={order.order_id}>
          <div className='orderItem'>
            <h3>{order.order_ref}</h3>
            <button
              className='form__button'
              onClick={() =>
                dispatch(addParticipant(order.customer_id, order.order_id))
              }
            >
              Confirm
            </button>
          </div>
          <ParticipantList participants={order.participants} />
        </div>
      ))}
      {alert ? (
        <Alert
          icon={alert.icon}
          message={alert.message}
          dismiss={handleDismiss}
        />
      ) : null}
    </>
  );
};

export default OrdersList;
