import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import { getOrderParticipants } from '../../actions/scannerActions';
import { CLEAR_CURRENT, CLEAR_SCANNER_MANUAL } from '../../types/scannerTypes';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import OrdersList from '../../components/OrdersList';

const ManualScanner = () => {
  const dispatch = useDispatch();
  const scannerSelector = useSelector((state) => state.scanner);
  const { loadingManual, orders, errorManual } = scannerSelector;
  const [customer, setCustomer] = useState('');

  const handleManualScan = () => {
    dispatch(getOrderParticipants(customer));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_SCANNER_MANUAL });
      dispatch({ type: CLEAR_CURRENT });
    };
  }, [dispatch]);

  return (
    <Fragment>
      <Header />
      <Menu />
      <Content>
        <h1>Guest Search</h1>
        <div className='form-group'>
          <label className='form__label' htmlFor='customer'>
            Email Address or Surname
          </label>
          <input
            className='form__input'
            type='text'
            id='customer'
            name='customer'
            value={customer}
            autoComplete={false}
            onChange={(e) => setCustomer(e.target.value)}
          />
          {errorManual ? <div className='error'>{errorManual}</div> : null}
        </div>
        <div className='form__buttons'>
          <button
            aria-label='Register'
            disabled={loadingManual}
            className='form__button form__button--inline'
            onClick={handleManualScan}
          >
            Search{' '}
            <PulseLoader
              loading={loadingManual}
              color={'#ffffff'}
              css={'margin-left: 8px'}
              size={5}
            />
          </button>
        </div>
        {orders.length > 0 ? <OrdersList orders={orders} /> : null}
      </Content>
      <BottomNav />
    </Fragment>
  );
};

export default ManualScanner;
