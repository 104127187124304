import React from 'react';
import Participant from './Participant';

const ParticipantList = ({ participants }) => {
  return (
    <div>
      {participants?.map((participant) => (
        <Participant
          key={participant.participant_id}
          participant={participant}
        />
      ))}
    </div>
  );
};

export default ParticipantList;
