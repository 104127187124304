import {
  ADD_ATTENDANCE_REQUEST,
  ADD_ATTENDANCE_SUCCESS,
  ADD_ATTENDANCE_FAIL,
  ADD_MANUAL_REQUEST,
  ADD_MANUAL_SUCCESS,
  ADD_MANUAL_FAIL,
  CLEAR_SCANNER_AUTO,
  CLEAR_SCANNER_MANUAL,
  CONFIRM_MANUAL_REQUEST,
  CONFIRM_MANUAL_SUCCESS,
  CONFIRM_MANUAL_FAIL,
  ADD_PARTICIPANT_REQUEST,
  ADD_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_FAIL,
  CLEAR_ORDERS,
  CLEAR_CURRENT,
} from '../types/scannerTypes';

const initialState = {
  current: null,
  currentManual: null,
  orders: [],
  loading: false,
  loadingManual: false,
  loadingConfirm: false,
  error: null,
  errorManual: null,
  errorConfirm: null,
  messageConfirm: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ATTENDANCE_REQUEST:
    case ADD_PARTICIPANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ATTENDANCE_SUCCESS:
    case ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        current: action.payload.message,
        loading: false,
        error: null,
      };
    case ADD_ATTENDANCE_FAIL:
    case ADD_PARTICIPANT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload || 'Internal server error',
      };
    case ADD_MANUAL_REQUEST:
      return {
        ...state,
        loadingManual: true,
        errorManual: null,
        orders: [],
      };
    case ADD_MANUAL_SUCCESS:
      return {
        ...state,
        loadingManual: false,
        errorManual: null,
        orders: action.payload?.orders || [],
      };
    case ADD_MANUAL_FAIL:
      return {
        ...state,
        loadingManual: false,
        errorManual: action.payload || 'Internal server error',
      };
    case CONFIRM_MANUAL_REQUEST:
      return {
        ...state,
        loadingConfirm: true,
        errorConfirm: null,
        currentManual: null,
      };
    case CONFIRM_MANUAL_SUCCESS:
      return {
        ...state,
        loadingConfirm: false,
        errorConfirm: null,
        messageConfirm: action.payload,
      };
    case CONFIRM_MANUAL_FAIL:
      return {
        ...state,
        loadingConfirm: false,
        messageConfirm: null,
        errorConfirm: action.payload,
      };
    case CLEAR_SCANNER_AUTO:
      return {
        ...state,
        loading: false,
        error: null,
        current: null,
      };
    case CLEAR_SCANNER_MANUAL:
      return {
        ...state,
        loadingManual: false,
        errorManual: null,
        currentManual: null,
        loadingConfirm: false,
        errorConfirm: null,
        messageConfirm: null,
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        orders: [],
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};
