import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Scanner from './pages/Scanner/Scanner';
import ManualScanner from './pages/ManualScanner/ManualScanner';
import NewCustomer from './pages/NewCustomer/NewCustomer';
import ProtectedRoute from './components/routes/ProtectedRoute';

import { getMenu } from './actions/appActions';

import './App.css';

const App = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const { isAuthenticated } = authSelector;

  useEffect(() => {
    dispatch(getMenu());
  }, [dispatch]);

  return (
    <Router>
      <div
        className={`app ${
          window.location.pathname === '/login' ? 'no-transition' : ''
        }`}
      >
        <Switch>
          <ProtectedRoute
            path='/'
            exact
            component={Scanner}
            isAuthenticated={isAuthenticated}
          />
          <Route path='/login' component={Login} />
          <ProtectedRoute
            path='/logout'
            component={Logout}
            isAuthenticated={isAuthenticated}
          />
          {/* <TestRoute path="/venue" component={Venue} isAuthenticated={isAuthenticated} /> */}
          <ProtectedRoute
            path='/guest-search'
            component={ManualScanner}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path='/manual-entry'
            component={NewCustomer}
            isAuthenticated={isAuthenticated}
          />
          <Redirect to='/' />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
