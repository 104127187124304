import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Logo from '../../assets/logo.jpg';
import Menu from '../../images/menu.svg';

import { toggleMenu } from '../../actions/appActions';

import './Header.css';

const Header = (props) => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const { user } = authSelector;
  const { isLogin } = props;

  return (
    <div className='header'>
      <div
        className={`header__top ${
          window.location.pathname === '/login' && 'header__topLogin'
        }`}
      >
        <img className='header__logo' src={Logo} alt='CMAC Event Scanner' />
        {!isLogin && (
          <button
            aria-label='Menu'
            onClick={() => dispatch(toggleMenu())}
            className='header__menuIcon'
          >
            <img src={Menu} alt='Menu' />
          </button>
        )}
      </div>
      {window.location.pathname === '/' ? (
        <div className='header__topMessage'>
          {user}
        </div>
      ) : null}
    </div>
  );
};

export default Header;
