export const ADD_ATTENDANCE_REQUEST = 'ADD_ATTENDANCE_REQUEST';
export const ADD_ATTENDANCE_SUCCESS = 'ADD_ATTENDANCE_SUCCESS';
export const ADD_ATTENDANCE_FAIL = 'ADD_ATTENDANCE_FAIL';
export const ADD_MANUAL_REQUEST = 'ADD_MANUAL_REQUEST';
export const ADD_MANUAL_SUCCESS = 'ADD_MANUAL_SUCCESS';
export const ADD_MANUAL_FAIL = 'ADD_MANUAL_FAIL';
export const CONFIRM_MANUAL_REQUEST = 'CONFIRM_MANUAL_REQUEST';
export const CONFIRM_MANUAL_SUCCESS = 'CONFIRM_MANUAL_SUCCESS';
export const CONFIRM_MANUAL_FAIL = 'CONFIRM_MANUAL_FAIL';
export const CLEAR_SCANNER_AUTO = 'CLEAR_SCANNER_AUTO';
export const CLEAR_SCANNER_MANUAL = 'CLEAR_SCANNER_MANUAL';

export const ADD_PARTICIPANT_REQUEST = 'ADD_PARTICIPANT_REQUEST';
export const ADD_PARTICIPANT_SUCCESS = 'ADD_PARTICIPANT_SUCCESS';
export const ADD_PARTICIPANT_FAIL = 'ADD_PARTICIPANT_FAIL';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';

export const CLEAR_ORDERS = 'CLEAR_ORDERS';
