import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QrReader from 'react-qr-reader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import Alert from '../../components/layout/Alert';
import OrdersList from '../../components/OrdersList';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

import { addToAttendance, clearOrders } from '../../actions/scannerActions';
import { CLEAR_SCANNER_AUTO } from '../../types/scannerTypes';

import './Scanner.css';

const Scanner = () => {
  const dispatch = useDispatch();
  const scannerSelector = useSelector((state) => state.scanner);
  const { loading, current, error, orders } = scannerSelector;
  const [alert, setAlert] = useState(null);
  const [data, setData] = useState('');
  const [cameraError, setCameraError] = useState(null);

  useEffect(() => {
    if (error) {
      setAlert({
        icon: <Cross style={{ color: '#e53e3e' }} />,
        message: error,
      });
      setData('');
    } else if (current) {
      console.log(current);
      setAlert({
        icon: <Check style={{ color: '#48BB78' }} />,
        message: current,
      });
    }

    return () => {
      dispatch({ type: CLEAR_SCANNER_AUTO });
    };
  }, [current, error]);

  useEffect(() => {
    if (data) {
      dispatch(addToAttendance(data));
      setData('');
    }
  }, [data]);

  useEffect(() => {
    dispatch(clearOrders());
  }, []);

  const handleDismiss = () => {
    setAlert(null);
  };

  const handleScan = (data) => {
    if (data) {
      setData(data);
    }
  };

  const handleError = (err) => {
    setCameraError(`${err.name}: ${err.message}`);
  };

  return (
    <div>
      <Header />
      <Menu />
      <Content style={{ marginTop: '100px' }}>
        <h1 className='scanner__title'>CMAC Event Scanner</h1>
        <p className='scanner__message'>
          Scan customer QR Code to record visit
        </p>
        {orders.length > 0 ? (
          <>
            <div>
              <button onClick={() => dispatch(clearOrders())}>
                Scan Again
              </button>
            </div>
            <OrdersList orders={orders} />
          </>
        ) : (
          <div className='scanner__scanner'>
            {!loading && <QrReader onScan={handleScan} onError={handleError} />}
            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
              {cameraError}
            </div>
          </div>
        )}
      </Content>
      <BottomNav />
      {alert && (
        <Alert
          icon={alert.icon}
          message={alert.message}
          dismiss={handleDismiss}
        />
      )}
    </div>
  );
};

export default Scanner;
