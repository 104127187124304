import Axios from 'axios';
import Cookie from 'js-cookie';

import {
    TOGGLE_MENU,
    CLOSE_MENU,
    NAVIGATION_REQUEST,
    NAVIGATION_FAIL,
    NAVIGATION_SUCCESS,
    MENU_REQUEST,
    MENU_SUCCESS,
    MENU_FAIL
} from '../types/appTypes';

export const toggleMenu = () => dispatch => {
    dispatch({ type: TOGGLE_MENU });
}

export const closeMenu = () => dispatch => {
    dispatch({ type: CLOSE_MENU });
}

export const setNavigation = () => async dispatch => {
    try {
        dispatch({ type: NAVIGATION_REQUEST });

        const navigation = await Axios.get('https://event.cmac.world/app_handler.php', { headers: {} });

        if (navigation) {
            dispatch({ type: NAVIGATION_SUCCESS, payload: navigation });
        }

    } catch (error) {
        dispatch({ type: NAVIGATION_FAIL });
    }
}

export const getMenu = () => async dispatch => {
    try {
        dispatch({ type: MENU_REQUEST });

        const { data } = await Axios.get('https://event.cmac.world/app_handler.php', { headers: { 'Wg-method': 'MENU', 'Wg-Key': Cookie.get('accessToken') } });

        if (data) {
            dispatch({ type: MENU_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: MENU_FAIL });
    }
};