import axios from 'axios';
import Cookie from 'js-cookie';

import { CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_FAIL } from '../types/registerCustomerTypes';

export const createCustomerAndScanIn = (firstname, lastname, email, repeatEmail, phone, table) => async dispatch => {
    try {
        dispatch({ type: CREATE_CUSTOMER_REQUEST });

        const { data } = await axios.post('https://event.cmac.world/app_handler.php', { firstname, lastname, email, repeat_email: repeatEmail, phone, table }, { headers: { 'WG-Method': 'CREATE_CUSTOMER_AND_SIGN_IN', "WG-Key": Cookie.get('accessToken') }});

        if (data) {
            dispatch({ type: CREATE_CUSTOMER_SUCCESS });
        }

    } catch (error) {
        dispatch({ type: CREATE_CUSTOMER_FAIL, payload: error?.response.data.errors });
    }
}